<template>
	<div class="main-content">
		<ktv-breadcrumb
			:folder="$t('Premium Points', 'Premium Points')"
			:page="$t('AO Package', 'AO Package')"
			:title="$t('AO Package Setting', 'AO Package Setting')"
		/>
		<ktv-table
			:columns="columns"
			:filter="false"
			:is-loading="is.loading"
			:line-numbers="false"
			:rows="filteredData"
			:search-enabled="false"
			:search-on-enter="false"
			:search-placeholder="$t('Search by district name or code', 'Search by district name or code')"
			:total-rows="aoPackageSettingList ? filteredData.length : 0"
			min-height="250px"
			mode="client"
			@on-search="onSearch"
		>
			<template #actionleft>
				<router-link v-if="isAllowedToAdd" :to="{ name: 'Premium Points AO Package Setting Add' }">
					<ktv-button :text="$t('Add', 'Add')" icon="add" />
				</router-link>
			</template>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
						<template #button-content>
							<img :src="icons.dropdown" />
						</template>
						<b-dropdown-item v-if="isAllowedToViewDetail" @click="showDetail(props.row)">
							<img :src="icons.view" class="imgicon" />{{ $t("Detail", "Detail") }}
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field === 'productPriorityId'">{{ props.row.productPriorityId >= 0 ? props.row.productPriorityId : "-" }}</span>
				<span v-else-if="props.column.field === 'districtId'">{{ props.row.districtId ? districtOptions[props.row.districtId] : "-" }}</span>
				<span v-else-if="props.column.field === 'aoQuestionNumber'">{{ props.row.aoQuestionNumber >= 0 ? props.row.aoQuestionNumber : "-" }}</span>
				<span v-else-if="props.column.field === 'dateUpdate'">{{ props.row.dateUpdate ? props.row.dateUpdate : "-" }}</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	import { each, filter, findWhere } from "underscore"
	import { KtvButton, KtvTable } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"

	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import viewIcon from "@/assets/images/icon/iconview.png"

	const icons = {
		dropdown: dropdownIcon,
		view: viewIcon,
	}

	export default {
		name: "PremiumPointsAOPackageList",
		metaInfo: {
			title: "AO Package Setting",
		},
		components: { KtvButton, KtvTable },
		data() {
			return {
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "aoQuestionNo",
						label: "AO Number",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "districtCode",
						label: "District Code",
						tdClass: "text-center",
						thClass: "text-center",
						width: "250px",
					},
					{
						field: "districtName",
						label: "District",
						thClass: "text-left",
					},
					{
						field: "total",
						label: "Total Product",
						tdClass: "text-center",
						thClass: "text-center",
						width: "250px",
					},
				],
				districtOptions: null,
				icons: icons,
				is: {
					loading: false,
					showing: {
						addModal: false,
					},
				},
				params: {
					limit: 10,
					offset: 0,
					order: null,
					search: null,
				},
				searchText: null,
			}
		},
		computed: {
			...mapGetters({
				premiumPointActions: "AUTHDATA/premiumPointActions",
			}),
			...mapState("OTHERS", ["districtList"]),
			...mapState("PREMIUMPOINT", ["aoPackageSettingList", "aoPackageSettingTotalRows"]),
			filteredData() {
				return this.searchText
					? filter(this.aoPackageSettingList, (product) => {
						const districtName = String(product.districtName).toLowerCase()
						const districtCode = String(product.districtCode).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return districtName.indexOf(searchText) > -1 || districtCode.indexOf(searchText) > -1
					})
					: this.aoPackageSettingList
			},
			isAllowedToAdd() {
				return this.getActionPermission("add")
			},
			isAllowedToViewDetail() {
				return this.getActionPermission("detail")
			},
		},
		watch: {
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
		},
		created() {
			this.getDistrictList().then(() => {
				this.getData()
			})
		},
		methods: {
			...mapActions({
				getAOPackageSettingList: "PREMIUMPOINT/getAOPackageSettingList",
				getDistrictList: "OTHERS/getDistrictList",
			}),
			closeAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: false,
				}
			},
			getActionPermission(name = null) {
				return findWhere(this.premiumPointActions, { function: name })
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getAOPackageSettingList(this.params).then(() => {
					this.is = {
						...this.is,
						loading: false,
					}
				})
			},
			getDistrictOptions() {
				each(this.districtList, (district) => {
					if (district.districtId && district.district) {
						this.districtOptions = {
							...this.districtOptions,
							[district.districtId]: district.district,
						}
					}
				})
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
			showAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: true,
				}
			},
			showDetail({ districtCode, aoQuestionNo }) {
				this.$router.push({ name: "Premium Points AO Package Setting Detail", params: { district: districtCode, ao: aoQuestionNo } })
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
